import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (username === 'dmpost' && password === 'Kamimi!') {
      navigate('/form', { state: { loggedIn: true } })
      console.log('ERROR')
    }
  }

  return (
    <div className="loginScreen">
      <form className="create" onSubmit={handleSubmit}>
        <h3>Login</h3>
        <input
          className="textInput"
          type="text"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          placeholder={'Nume Utilizator'}
        ></input>
        <input
          className="textInput"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          placeholder={'Parola'}
        ></input>

        <button className="formButton">Login</button>
      </form>
    </div>
  )
}

export default Login
