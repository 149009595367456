import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Image from '../assets/RaemiWeb.png'
import Welcome from '../components/Welcome'

function Home() {
  return (
    <Container fluid id="home">
      <Row className="homeRow">
        <Col md="4" className="welcomeImage">
          <img src={Image} alt="WelcomeImage" className="welcomeImage" />
        </Col>
        <Col md="8" className="welcomeContainer">
          <Welcome />
        </Col>
      </Row>
    </Container>
  )
}

export default Home
