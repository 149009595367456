import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Collapsible from './Collapsible'
import { useItemsContext } from '../hooks/useItemsContext'
import { useNavigate } from 'react-router-dom'

function Welcome() {
  const { items, dispatch } = useItemsContext()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchItems = async () => {
      const response = await fetch(
        'https://rlist-api.onrender.com/api/listItems'
      )
      const json = await response.json()
      console.log(json)

      if (response.ok) {
        dispatch({ type: 'SET_ITEMS', payload: json })
      }
    }

    fetchItems()
  }, [dispatch])

  // const [isChanged, setIsChanged] = useState(false)

  // const data = ItemArray

  // const updateCheckStatus = async (id) => {

  //   const modifiedData = items.filter((item) => {
  //     if (item.id === id) {
  //       item.checked = !item.checked
  //     }
  //     return item
  //   })
  //   setChecked(modifiedData)
  //   console.log(modifiedData)
  //   setIsChanged(true)
  // }

  // console.log(JSON.parse(localStorage.getItem('ItemArray')))
  // if (isChanged) {
  //   const updatedData = JSON.parse(localStorage.getItem('ItemArray'))
  //   console.log(updatedData)
  // }
  // console.log(updatedData)

  // const updateCheckStatus = (id) => {
  //   const oldData = JSON.parse(localStorage.getItem("ItemArray"));
  //   const checkedData = oldData.map((item) => {
  //     if (item.id === id) {
  //       const newData = { ...item, checked: !item.checked };
  //       console.log(localStorage.setItem("ItemArray", JSON.stringify(newData)));
  //     }
  //   });
  //   return checkedData;
  //   // setItems(
  //   //   data.map((item) =>
  //   //     item.id === id ? { ...item, checked: !item.checked } : item
  //   //   )
  //   // );
  //   // console.log(items);
  // };
  // const checkedData = data.map((item) => {
  //   return item.checked
  // })

  // const updateCheckStatus = async (id) => {
  //   console.log(
  //     items.map((item) =>
  //       item._id === id ? { ...item, checked: !item.checked } : item
  //     )
  //   )
  //   const updatedItems = items.map((item) =>
  //     item._id === id ? { ...item, checked: !item.checked } : item
  //   )

  // const response = await fetch(
  //   'https://rlist-api.onrender.com/api/listItems/' + id,
  //   {
  //     method: 'PUT',
  //     body: JSON.stringify({

  //         title: ,
  //         location: location,
  //         price: price,
  //         description: description,
  //         checked: !checked

  //     }),
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   }
  // )
  // const json = await response.json()

  // console.log(json)

  // if (response.ok) {
  //   console.log('Update item', json)
  //   dispatch({ type: 'UPDATE_ITEM', payload: json })
  // }
  // }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            {items &&
              items.map((item) => (
                <Collapsible
                  id={item._id}
                  title={item.title}
                  location={item.location}
                  price={item.price}
                  description={item.description}
                  key={item._id}
                  checked={item.checked}
                  // key={item.id}
                  // checkHandler={() => updateCheckStatus(item._id)}
                />
              ))}
            <button
              className="loginButton"
              onClick={() => {
                navigate('/login')
              }}
            >
              Login
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Welcome
