import React, { useState } from 'react'
import { useItemsContext } from '../hooks/useItemsContext'

function Collapsible({
  id,
  title,
  location,
  price,
  description,
  checked,
  loggedIn
}) {
  const [open, setOpen] = useState(checked)
  const [tempChecked, setChecked] = useState(checked)

  const toggle = () => {
    setOpen(!open)
  }

  const { dispatch } = useItemsContext()

  const handleClick = async () => {
    const response = await fetch(
      'https://rlist-api.onrender.com/api/listItems/' + id,
      {
        method: 'DELETE'
      }
    )
    const json = await response.json()

    if (response.ok) {
      dispatch({
        type: 'DELETE_ITEM',
        payload: json
      })
    }
  }

  const handleChange = async () => {
    const response = await fetch(
      'https://rlist-api.onrender.com/api/listItems/' + id,
      {
        method: 'PUT',
        body: JSON.stringify({
          title: title,
          location: location,
          price: price,
          description: description,
          checked: !checked
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
    const json = await response.json()

    setChecked(!checked)

    console.log(json)

    if (response.ok) {
      console.log('Update item', json)
      dispatch({ type: 'UPDATE_ITEM', payload: json })
    }
  }

  return (
    <div className="collapsContainer">
      <div className="buttonContainer">
        <button onClick={toggle} className="button" disabled={tempChecked}>
          {title}
        </button>
      </div>
      {open && tempChecked === false && (
        <div className="insideCollapsable">
          <input
            className="input"
            id={id}
            type="checkbox"
            defaultChecked={checked}
            onChange={handleChange}
          />
          <div className="labelList">
            <li className="labelItem">Locație: {location}</li>
            <li className="labelItem">Articol: {title}</li>
            <li className="labelItem">Preț: {price}</li>
            <li className="labelItem">Descriere: {description}</li>
          </div>
        </div>
      )}
      {loggedIn && <button className='deleteButton' onClick={handleClick}>Șterge</button>}
    </div>
  )
}

export default Collapsible
