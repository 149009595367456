import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useItemsContext } from '../hooks/useItemsContext'
import Collapsible from '../components/Collapsible'
import UpdateFormComponent from '../components/UpdateFormComponent'

const Form = () => {
  const { items, dispatch } = useItemsContext()
  const [title, setTitle] = useState('')
  const [location, setLocation] = useState('')
  const [price, setPrice] = useState('')
  const [description, setDescription] = useState('')
  const [error, setError] = useState(null)
  const [emptyFields, setEmptyFields] = useState([])

  const loginState = useLocation()

  console.log(loginState.state)

  useEffect(() => {
    const fetchItems = async () => {
      const response = await fetch(
        'https://rlist-api.onrender.com/api/listItems'
      )
      const json = await response.json()
      console.log(json)

      if (response.ok) {
        dispatch({ type: 'SET_ITEMS', payload: json })
      }
    }

    fetchItems()
  }, [dispatch])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const item = { title, location, price, description }

    const response = await fetch(
      'https://rlist-api.onrender.com/api/listItems',
      {
        method: 'POST',
        body: JSON.stringify(item),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    const json = await response.json()

    console.log(json)

    if (!response.ok) {
      setError(json.error)
      setEmptyFields(json.emptyFields)
    }
    if (response.ok) {
      setTitle('')
      setLocation('')
      setPrice('')
      setDescription('')
      setError(null)
      setEmptyFields('')
      console.log('New workout added', json)
      dispatch({ type: 'CREATE_ITEM', payload: json })
    }
  }

  return (
    <div className="formScreen">
      <form className="create" onSubmit={handleSubmit}>
        <h3>Adauga produs</h3>

        <label className="textLabel">Denumire Produs:</label>
        <input
          className={emptyFields.includes('title') ? 'textError' : 'textInput'}
          type="text"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          placeholder={
            emptyFields.includes('title') ? 'Please fill in title' : ''
          }
        ></input>

        <label className="textLabel">Locație Produs:</label>
        <input
          className={
            emptyFields.includes('location') ? 'textError' : 'textInput'
          }
          type="text"
          onChange={(e) => setLocation(e.target.value)}
          value={location}
          placeholder={
            emptyFields.includes('location') ? 'Please fill in location' : ''
          }
        ></input>

        <label className="textLabel">Preț Produs:</label>
        <input
          className={emptyFields.includes('price') ? 'textError' : 'textInput'}
          type="text"
          onChange={(e) => setPrice(e.target.value)}
          value={price}
          placeholder={
            emptyFields.includes('price') ? 'Please fill in price' : ''
          }
        ></input>

        <label className="textLabel">Descriere Produs:</label>
        <input
          className={
            emptyFields.includes('description') ? 'textError' : 'textInput'
          }
          type="text"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          placeholder={
            emptyFields.includes('description')
              ? 'Please fill in description'
              : ''
          }
        ></input>

        <button className="formButton">Adaugă Produs</button>
        {error && <div className="error">{error}</div>}
      </form>

      {items &&
        items.map((item) => (
          <div>
            <Collapsible
              id={item._id}
              title={item.title}
              location={item.location}
              price={item.price}
              description={item.description}
              key={item._id}
              checked={item.checked}
              loggedIn={loginState.state.loggedIn}
            />
            <UpdateFormComponent
              id={item._id}
              checked={item.checked}
              key={item._id}
            />
          </div>
        ))}
    </div>
  )
}

export default Form
