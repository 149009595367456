import React, { useState } from 'react'
import { useItemsContext } from '../hooks/useItemsContext'

export default function UpdateFormComponent({ id, checked }) {
  const [title, setTitle] = useState('')
  const [location, setLocation] = useState('')
  const [price, setPrice] = useState('')
  const [description, setDescription] = useState('')
  const [open, setOpen] = useState(false)
  const { dispatch } = useItemsContext()

  const handleSubmit = async () => {
    const response = await fetch(
      'https://rlist-api.onrender.com/api/listItems/' + id,
      {
        method: 'PUT',
        body: JSON.stringify({
          title: title,
          location: location,
          price: price,
          description: description,
          checked: checked,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const json = await response.json()

    console.log(json)

    if (response.ok) {
      console.log('Update item', json)
      dispatch({ type: 'UPDATE_ITEM', payload: json })
    }
  }

  const toggle = () => {
    setOpen(!open)
  }

  return (
    <div>
      <div className="buttonContainer">
        <button onClick={toggle} className="deleteButton">
          {'Actualizează'}
        </button>
      </div>
      {open && (
        <form className="create" onSubmit={handleSubmit}>
          <label className="textLabel">Actualizează Denumire:</label>
          <input
            className={'textInput'}
            type="text"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          ></input>

          <label className="textLabel">Actualizează Locație:</label>
          <input
            className={'textInput'}
            type="text"
            onChange={(e) => setLocation(e.target.value)}
            value={location}
          ></input>

          <label className="textLabel">Actualizează Preț:</label>
          <input
            className={'textInput'}
            type="text"
            onChange={(e) => setPrice(e.target.value)}
            value={price}
          ></input>

          <label className="textLabel">Actualizează Descriere:</label>
          <input
            className={'textInput'}
            type="text"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          ></input>

          <button className="formButton">Actualizează Produs</button>
        </form>
      )}
    </div>
  )
}
